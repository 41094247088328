<template>
<div >
	

			<v-card>
				<v-toolbar flat color="white">
                    <v-toolbar-title>Buscar</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    >
                    </v-divider>
                    <v-spacer></v-spacer>
                    
                    <v-autocomplete 
                        label="Año"
                        class="mt-3"
                        v-model=anioSel
                        :items=listaAnios
                        item-value="custom_time_period_id"
                        item-text="period_name"
                       
                        @change="cargarLista"
                    >   

                    </v-autocomplete>
                    
                   <!-- <v-text-field                        
                        append-icon="mdi-magnify"
                        label="Tipo"
                        single-line                    
                        class='mt-3'
                        @keydown.enter=cargarLista(true)
                        @click:append=cargarLista(true)
                    ></v-text-field> -->
    </v-toolbar>
	<v-toolbar flat color="white">
		<v-btn  color="primary" small  class="ma-2 white--text" 
		@click="nuevoPeriodo()"	>
			<v-icon left >mdi-plus</v-icon>
		Nuevo
		</v-btn>

        
	</v-toolbar>
	
	  <v-row>
         <v-col md="12" sm="12">
         <v-data-table
			    :headers="headers"
			    vm-model="cargarLista"
			    :items="lista"
			    :items-per-page="1000"
			    :loading="loadingTable"
			    hide-default-footer
			    class="elevation-1"
			  >
			   <template v-slot:item.is_closed="{item}" >
                {{item.is_closed == "Y" ? "Si" : "No"}}
               </template>
			   <template v-slot:item.opcion="{item}">
                <v-btn v-if="item.is_closed=='N'" @click="cerrarPeriodo(item)"
                    icon                      
                >
                    <v-icon left color='green' >mdi-door-closed-lock</v-icon>
                    Cerrar
                </v-btn>

                <v-btn v-if="item.is_closed=='Y'" @click="abrirPeriodo(item)"
                    icon                      
                >
                    <v-icon left color='green' >mdi-door</v-icon>
                    Abrir
                </v-btn>
               
            </template> 

                <template v-slot:item.editar="{item}">
                <v-btn @click="editarPeriodo(item)"
                    icon                      
                >
                    <v-icon color='green' >mdi-pencil</v-icon>
                    
                </v-btn>
               
            </template> 
            
           <!--  <template slot="body.append">
                    <tr class="pink--text">
                        <th class="title">Total</th>
                        <th class="title"></th>
                        <th class="title"></th>
                        <th class="title"></th>
                        <th class="title"></th>
                        <th class="title"></th>                        
                        <th class="title">{{suma('total')}}</th>
                        <th class="title">{{suma('retendio')}}</th>
                        <th class="title">{{suma('pagado')}}</th>                        
                        <th class="title" align="right">{{suma('saldo')}}</th>
                    </tr>
                </template> -->
            
			  </v-data-table>
			  </v-col>
         </v-row>
         </v-card>
         


 <v-dialog v-model="dialogPeriodo" width="500" >
    <v-card class="ma-0 pa-0">
        <v-toolbar color="green" height="40" flat dark>
            <v-toolbar-title>Período contable</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="dialogPeriodo=!dialogPeriodo" small dark icon><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-row class="pa-4 ma-0">
            <v-col cols="12" class="pa-0">
                <v-select label="Tipo de período" v-model="datosPeriodo.period_type_id" :items="tiposPeriodo" item-text="description" item-value="period_type_id"></v-select>
            </v-col>

            <v-col cols="12" class="pa-0">
                <v-select label="Período padre" v-model="datosPeriodo.parent_period_tid" :items="periodosPadre" item-text="description" item-value="custom_time_period_id"></v-select>
            </v-col>

            <v-col cols="12" class="pa-0">
                <v-text-field type="date" label="Desde" v-model="datosPeriodo.from_date" ></v-text-field>
            </v-col>

            <v-col cols="12" class="pa-0">
                <v-text-field type="date" label="Hasta" v-model="datosPeriodo.thru_date" ></v-text-field>
            </v-col>
        </v-row>

        <v-row class="pt-4 ma-0">
            <v-col cols="12" class="pa-4 ma-0">
                <v-btn class="mr-4" small color="green" dark  ><v-icon>mdi-content-save</v-icon>Guardar</v-btn>
                
                <v-btn class="mx-4" small text @click="dialogPeriodo=!dialogPeriodo" color="blue" dark  ><v-icon>mdi-cancel</v-icon>Cancelar</v-btn>
            </v-col>
        </v-row>
    </v-card>
 </v-dialog>
         
</div>
</template>


<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import Vue from 'vue'

export default {
	name: "PeriodoContableComponent",
	
	data: ()=> ({
    	 headers: [
             { text: 'Editar', value: 'editar', width: 75 },
    		 
    		 { text: 'Tipo', value: 'period_type', width:30 },
             { text: 'Nombre', value: 'period_name', width: 30 },
    		 { text: 'Numero', value: 'period_num', width: 30 },
             { text: 'Desde', value: 'from_date', width:100 },
             { text: 'Hasta', value: 'thru_date', width:100 },
             { text: 'Está cerrado', value: 'is_closed', width:40 },
             { text: 'Opción', value: 'opcion', width:100 }
             
           ],
            lista :[],
            listaAnios :[],
            options: {},
            dialogPeriodo: false,
            notifications: false,
            sound: true,
            widgets: true,
           
            currentPage: 1,
            pageCount: 1,
            itemsPerPage: 20,
            anioSel: '',
            tiposPeriodo:[
                {period_type_id: "FISCAL_YEAR", description: "Año fiscal"},
                {period_type_id: "FISCAL_MONTH", description: "Mes fiscal"}
            ],
            datosPeriodo:{custom_time_period_id:"",parent_period_id: "",period_type_id:""},

             periodosPadre: []
    }),
    computed: {
    	...mapState('master',['loadingTable','user','tenantId']),    	
    	...mapGetters('access', ['btnAbrirCaja'])
    },
    methods: {
    	...mapMutations('master',['setUrl','setOverlay','setMenu','setTitleToolbar','setLoadingTable']),
        ...mapActions('master',['requestApi','alertNotification']),
	   
	     
	    cargarLista(filtrar) {
            
    		if(filtrar == true) {
    			this.currentPage = 1
    		}
    		this.setLoadingTable(true)
    		
            this.setUrl('periodo-contable')
            this.requestApi({
                method : 'GET',
                 async: false,
                data : {                	                
			        "page": this.currentPage, 
			        "page_count": this.pageCount, 
			        "page_size": this.itemsPerPage,
			        "tipo" : 'meses-periodo',
			        "periodo": this.anioSel
			        
			    }
            }).then(res=>{
            	//console.log(res.data.lista);			        			    		
            	this.lista = res.data._embedded.periodo_contable;
            	//this.pageCount = res.data.page_count;
            	this.setLoadingTable(false)
            }).then(()=>{
	              
            })
        	  

        },
        
        anios() {
    		
    		this.setLoadingTable(true)

            this.setUrl('periodo-contable')
                this.requestApi({
                    method : 'GET',
                    data : {                	                
                        "page": this.currentPage, 
                        "page_count": this.pageCount, 
                        "page_size": this.itemsPerPage,
                        "tipo" : 'anios-abiertos'
                    }
                }).then(res=>{
                    this.listaAnios = res.data._embedded.periodo_contable;  
                    
                     
                    this.setLoadingTable(false)
                }).then(()=>{
                    
                });
        	  

        },
        nuevoPeriodo() {
            this.dialogPeriodo=true;

        },
        editarPeriodo(item) {
            this.dialogPeriodo=true;
            this.cargarDatosPeriodo(item)

        },

        cargarDatosPeriodo(item) {
            this.setLoadingTable(true)

            this.setUrl('periodo-contable/'+item.custom_time_period_id+"/datos-periodo")
                this.requestApi({
                    method : 'GET',
                    data : {                	                                        
                        
                    }
                }).then(res=>{
                    this.datosPeriodo = res.data.detail
                    
                    
                    this.setLoadingTable(false)
                }).then(()=>{
                    
                });
  
        },
        cerrarPeriodo(item) {
            Vue.swal({
                html: "Está seguro de cerrar este período contable ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {
                
                if (result.isConfirmed) {

                    
                    this.setUrl('periodo-contable')

                    this.requestApi({
                        method: 'POST',
                        data: {
                            accion: 'cerrarPeriodo',
                            custom_time_period_id: item.custom_time_period_id,
                            
                        }
                    }).then(res =>{
                        console.log(res.data.detail)
                        this.alertNotification({
                            param:{
                                html: res.data.detail
                            }
                            
                        })  
                        this.cargarLista() 

                    }).catch(()=>{
                        
                    })

                }

            })
        },

        abrirPeriodo(item) {
            Vue.swal({
                html: "Está seguro de abrir este período contable ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {
                
                if (result.isConfirmed) {

                    
                    this.setUrl('periodo-contable')

                    this.requestApi({
                        method: 'POST',
                        data: {
                            accion: 'abrirPeriodo',
                            custom_time_period_id: item.custom_time_period_id,
                            
                        }
                    }).then(res =>{
                        console.log(res.data.detail)
                        this.alertNotification({
                            param:{
                                html: res.data.detail
                            }
                            
                        })  
                        this.cargarLista() 

                    }).catch(()=>{
                        
                    })

                }

            })
        }

        
         
	},
	       
     
     mounted() {
        this.setTitleToolbar('PERIODOS CONTABLES')
        

         this.anios()   
         this.setUrl('periodo-contable')                                                                                                                                                                          
            this.requestApi({
                method : 'GET',
                async: false,
                data : {                	                
			        "page": this.currentPage, 
			        "page_count": this.pageCount, 
			        "page_size": this.itemsPerPage,
			        "tipo" : 'periodo-actual'
			    }
            }).then(res=>{
            	
            	this.anioSel = res.data._embedded.periodo_contable[0].periodoAnio;
                  
            	this.cargarLista(false);


            	this.setLoadingTable(false)
            }).then(()=>{
	              
            });         
    },
    
    watch: {
    	
    	searchNumber : {
    		handler() {
    			this.cargarLista(true)
    		}
    	},
    	searchEstado : {
    		 handler () {
                 this.cargarLista(true)
             },
    	}
    }

    
  }

</script>